import React, { useEffect } from "react"
import { Link } from "gatsby"
import styles from "@one-builder/gatsby-theme-ob-master/src/styles/global.module.scss"

// Components
import Layout from "../components/layout/layout"
import OfferForm from "../components/offer-form"
import { useGetTokens } from "@one-builder/gatsby-theme-ob-master/src/hooks/useGetTokens"
import convertTokens from "@one-builder/gatsby-theme-ob-master/src/helpers/convertTokens"
import Image from "@one-builder/gatsby-theme-ob-master/src/components/common/image"

export default ({ location }) => {
  useEffect(() => {
    let body = document.querySelector("body")
    if (body && !body.classList.contains("covid-landing-page")) {
      body.classList.add("covid-landing-page")
    } else {
      body.classList.remove("covid-landing-page")
    }
  }, [])

  // Get metadata
  const metadata = useGetTokens()

  let seo_data = {
    title: `Three Months Free + $0 Setup | {{CompanyName}}`,
    description: `{{CompanyName}} wants to help you in such times. Share with us some of your struggles and together we can find solution(s). Answer a few questions.`,
    page_visible: true,
  }

  return (
    <Layout location={location} is_landing={true} seo_data={seo_data}>
      <section
        id="covid-extended-row1"
        className={`${styles.covidExtendedRow}`}
      >
        <div className={styles.container}>
          <div className="homecontent">
            <h1>Three Months Free + $0 Setup</h1>
            <img
              id={styles.constructionMan}
              src="/images/construction-man.svg"
              alt="Digital marketing for construction businesses"
            />
          </div>
        </div>
        <div id={styles.covidExtendedFormContain}>
          <div className="homecontent">
            <p>
              {convertTokens(
                `{{CompanyName}} wants to help you in such times. Please complete the form to get the receive the Three Months Free and $0 Setup offer.`,
                metadata
              )}
            </p>
          </div>
          <OfferForm form_name="CovidExtendedOffer" extended_offer={true} />
        </div>
      </section>

      <section
        id="covid-extended-row2"
        className={`${styles.covidExtendedRow}`}
      >
        <div className={styles.container}>
          <Link id={styles.covidLogoLink} to="/" title="OneBuilder - Home">
            <Image filename={`logo`} alt="OneBuilder" />
          </Link>
        </div>
      </section>
    </Layout>
  )
}
